import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';

import MetaTags from '../../../components/MetaTags';

import { theme } from '../../../styles/theme';

import Background from '../../../components/Background';
import Header from '../../../components/Header2';
import HowGroupGiftsWork from '../../../sections/occasion/HowGroupGiftsWork';

import CuratedGifts from '../../../sections/occasion/CuratedGifts';

import GroupCard from '../../../sections/occasion/GroupCard';
import LoveIllume from '../../../sections/occasion/LoveIllume';
import JoinOccasions from '../../../sections/JoinOccasions';
import Footer from '../../../sections/common/FooterWithNav';
import futureCelebrating from '../../../images/occasion/birthday-best-friend.png';
import futurePhone from '../../../images/occasion/iPhone12.png';

import data from '../../../sections/occasion/data';
import HeaderSection from '../../../sections/occasion/HeaderSection';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionBirthdayBestFriend: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={'Show you care with group birthday ecards and gifts from illume.'}
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={'Group Birthday Gifts for your Best Friend'}
          paragraphs={[
            'Tim’s birthday is coming up but your friend group is a bit spread out…Different schedules? Try different time zones! Don’t let that slow you down. Celebrate with a group gift from illume.',
            'Pick out a fun gift from illume’s digital marketplace, invite the whole family to contribute with cash, messages, photos, and more, and then hit send before the big day. Tim will wake up with a beautiful surprise – that easy.',
          ]}
          buttonLink={
            'https://web.illumenotes.com/receive?c=c22b7958-384d-4b78-a578-6ae25abe784d&email=email@tryillume.com'
          }
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Birthday for Best Friend',
            },
            phone: {
              image: futurePhone,
              label: 'Birthday for Best Friend Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions joinMessage={'of celebrating birthdays'} />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionBirthdayBestFriend;
